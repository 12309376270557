<template>
  <div class="main-container">
    <h1>{{ $t("register.learningFrequencyTitle") }}</h1>

    <ButtonChoiceSelector
      :title="$t('register.learningFrequencyTitle')"
      :subtitle="$t('register.learningFrequencySubtitle')"
      :options="learningFrequencyOptions"
      :modelColumnName="'learning_frequency'"
      v-model="learningFrequency"
      @selector-value-chosen="frequencyChosen"
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
import userService from "@/services/userService";
const learningFrequency = ref(null);

import ButtonChoiceSelector from "@/components/ButtonChoiceSelector.vue";
import { learningFrequencyOptions } from "@/constants/learningFrequency.js";

onMounted(async () => {
  try {
    const userData = await getUserData();

    if (userData.learning_frequency) {
      learningFrequency.value = userData.learning_frequency;
    }
  } catch (error) {
    console.error(error.message);
  }
});

async function getUserData() {
  try {
    return await userService.getUserData();
  } catch (error) {
    alert(error.message);
  }
}

function frequencyChosen() {
  router.push("/register/step-7");
}
</script>

<style>
.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
