// Interests.js
export const Interests = [
  "Travel and Tourism",
  "Food and Cooking",
  "Sports and Fitness",
  "Music and Performing Arts",
  "Movies and Television",
  "Books and Literature",
  "Art and Design",
  "Technology and Gadgets",
  "Science and Nature",
  "History and Culture",
];
