<template>
  <div class="popup" v-if="teacher" @click="closePopup">
    <div class="popup-content" @click.stop>
      <Avatar
        ref="avatarComponent"
        :teacher="teacher"
        :variant="'teacher-details'"
        @speaking-stopped="speakingStopped"
        @avatar-loaded="avatarLoaded"
      ></Avatar>
      <h3>{{ teacher.name }}</h3>
      <p>{{ teacher.description }}</p>
      <div class="buttons-container">
        <LinguaButton @click="$emit('select-teacher')">{{
          $t("teacher.choose")
        }}</LinguaButton>
        <LinguaButton @click="listenTeacherVoice" :variant="'clean'">
          <img
            src="/assets/images/play.svg"
            style="height: 30px; width: 30px"
          />
        </LinguaButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { onMounted, onUnmounted } from "vue";

import LinguaButton from "./LinguaButton.vue";
import teacherService from "@/services/teacherService";
import { processResponseWithAudio, getAudioUrl } from "../utils/audioResponse";
import Avatar from "./Avatar.vue";

const props = defineProps({
  teacher: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(["close"]);
const avatarComponent = ref(null);

function closePopup() {
  avatarComponent.value.stopAudio();
  emit("close");
}

const handleEscapeKey = (event) => {
  if (event.key === "Escape") {
    onEscapePressed();
  }
};

const onEscapePressed = () => {
  closePopup();
};

onMounted(() => {
  window.addEventListener("keydown", handleEscapeKey);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleEscapeKey);
});

async function listenTeacherVoice() {
  let teacherId = props.teacher.id;

  try {
    const response = await teacherService.voiceSample({ teacherId });

    const { responseJson, responseText, buffer, contentLength } =
      await processResponseWithAudio(response);

    const audioUrl = getAudioUrl(buffer, contentLength);
    if (audioUrl) {
      avatarComponent.value.speak(responseText, audioUrl, responseJson.visemes);
    }
  } catch (error) {
    console.log("Can't handle voice sample", error);
  }
}
</script>

<style scoped>
.buttons-container {
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
