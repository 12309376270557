<template>
  <div class="main-container">
    <h1>{{ $t("language.chooseLevel") }}</h1>
    <LanguageLevel @level-chosen="levelChosen" />
  </div>
</template>

<script setup>
import LanguageLevel from "../components/LanguageLevel.vue";
import { useRouter } from "vue-router";

const router = useRouter();
const levelChosen = async () => {
  router.push("/register/step-4", {});
};
</script>
