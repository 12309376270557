<template>
  <div class="main-container">
    <h1>{{ $t("login.title") }}</h1>
    <div class="form-container">
      <div class="form-elements">
        <input
          v-model="email"
          type="email"
          :placeholder="$t('login.enterEmail')"
        />
        <input
          v-model="password"
          type="password"
          :placeholder="$t('login.password')"
        />
        <LinguaButton @click="login">
          {{ $t("login.loginButton") }}
        </LinguaButton>
        <div class="reset-wrapper">
          <a @click="resetPassword" class="reset-button">
            {{ $t("login.resetPasswordButton") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { auth } from "../utils/firebase.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import LinguaButton from "../components/LinguaButton.vue";
import userService from "@/services/userService";

const router = useRouter();
const email = ref("");
const password = ref("");

const login = async () => {
  try {
    await signInWithEmailAndPassword(auth, email.value, password.value)
    //GET USER DATA AND IF NOT ACTIVE CALL: auth.signOut();
    try {
      await userService.getUserData();
    } catch (error) {
      auth.signOut();
      throw new Error('User inactive')
    }
    router.push("/");
  } catch (error) {
    alert(error.message);
  }
};

const resetPassword = async () => {
  router.push("/reset-password");
};
</script>

<style>
.main-container {
  width: 100%; /* Ensures the container takes the full width */
}
.reset-button {
  padding-top: 20px;
  width: 100%;
  display: inline-block; /* Allows the link to be styled like a block but only as wide as its content */
  text-decoration: none; /* Removes underline from link */
}
</style>
