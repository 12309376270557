<template>
  <div class="popup" v-if="lesson" @click="closePopup">
    <div class="popup-content lesson-popup" @click.stop>
      <div>
        <TeacherBox
          :teacher="teacher"
          :lesson-id="lesson.id"
          :mode="'lesson-details'"
        ></TeacherBox>
      </div>
      <div class="lesson-box-details">
        <h3>{{ lesson.name }}</h3>
        <div>{{ lesson.description }}</div>
        <div>{{ lesson.language_level }}</div>
        <LinguaButton @click="selectLesson">
          {{ $t("lesson.start") }}
        </LinguaButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, onUnmounted } from "vue";
import LinguaButton from "./LinguaButton.vue";
import TeacherBox from "./TeacherBox.vue";
import lessonService from "../services/lessonService";

const router = useRouter();

const props = defineProps({
  lesson: {
    type: Object,
    required: true,
  },
  teacher: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["close"]);

async function selectLesson() {
  try {
    await lessonService.start(props.lesson.id, props.teacher.id);
    //avram
    router.push("/lesson", {
      props: { lesson: () => props.lesson, teacher: () => props.teacher },
    });
  } catch (error) {
    console.error(error.message);
  }
}

function closePopup() {
  emit("close");
}

const handleEscapeKey = (event) => {
  if (event.key === "Escape") {
    onEscapePressed();
  }
};

const onEscapePressed = () => {
  closePopup();
};

onMounted(() => {
  window.addEventListener("keydown", handleEscapeKey);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleEscapeKey);
});
</script>

<style>
/* Styling for the teacher box */
.lesson-popup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.lesson-box-details {
  border-top: 1px solid #ddd;
  padding: 20px;
  margin: 10px;
}
@media (min-width: 600px) {
  .lesson-box-details {
    border-top: none;
    border-left: 1px solid #ddd;
  }
}

@media (min-width: 600px) {
  .lesson-popup {
    flex-wrap: nowrap;
  }
}
</style>
