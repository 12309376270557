<template>
  <div class="picker-container">
    <div class="picker-label">{{ $t("profile.nativeLanguage") }}</div>
    <div class="language-picker">
      <select v-model="selectedLanguage" @change="updateLanguage">
        <option
          v-for="(language, code) in languages"
          :key="code"
          :value="language"
        >
          {{ language }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { languages } from "../constants/languages.js";
import userService from "@/services/userService";

const props = defineProps({
  language: String,
});

const selectedLanguage = ref(props.language);

watch(
  () => props.language,
  (newLanguage) => {
    selectedLanguage.value = newLanguage;
  }
);

// Set the selectedLanguage based on the initial prop value on component mount
onMounted(() => {
  selectedLanguage.value = props.language;
});

function updateLanguage() {
  userService.setUserData({ native_language: selectedLanguage.value });
}
</script>

<style scoped>
.language-picker select {
  padding: 8px;
  width: 200px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.picker-label {
  width: 150px;
  padding: 10px;
  text-align: right;
}
.picker-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}
</style>
