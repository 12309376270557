<template>
  <div class="popup" @click="closePopup">
    <div class="popup-content multi-step-rating" @click.stop>
      <div v-if="!isSubmitted">
        <div v-if="currentStep < steps.length - 1">
          <h2>{{ steps[currentStep].question }}</h2>
          <StarRating
            :key="currentStep"
            :modelValue="ratings[currentStep]"
            @rating-updated="ratingUpdated(currentStep, $event)"
          />
        </div>
        <div v-else>
          <h2>{{ steps[currentStep].question }}</h2>
          <textarea
            v-model="remarks"
            :placeholder="$t('feedback.enterYourRemarks')"
          ></textarea>
        </div>
        <div class="navigation-buttons">
          <button @click="prevStep" :disabled="currentStep === 0">
            {{ $t("common.previous") }}
          </button>
          <button @click="nextStep">
            {{
              currentStep < steps.length - 1
                ? $t("common.next")
                : $t("common.submit")
            }}
          </button>
        </div>
      </div>
      <div class="thank-you" v-else>
        <h2>{{ $t("feedback.thankYouTitle") }}</h2>
        <p>{{ $t("feedback.thankYouSubtitle") }}</p>
        <button @click="closeFeedback">{{ $t("common.close") }}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { eventBus } from "../utils/eventBus";
import StarRating from "../components/StarRating.vue";
import lessonService from "../services/lessonService";

const props = defineProps({
  steps: {
    type: Array,
    required: true,
  },
  lesson: {
    type: Object,
    required: true,
  },
});

const currentStep = ref(0);
const ratings = ref(Array(props.steps.length - 1).fill(0));
const remarks = ref("");
const isSubmitted = ref(false);

const emit = defineEmits(["close"]);

function closePopup() {
  emit("close");
}

const handleEscapeKey = (event) => {
  if (event.key === "Escape") {
    onEscapePressed();
  }
};
onMounted(() => {
  window.addEventListener("keydown", handleEscapeKey);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleEscapeKey);
});

const onEscapePressed = () => {
  closePopup();
};

const nextStep = () => {
  if (currentStep.value < props.steps.length - 1) {
    currentStep.value++;
  } else {
    submitFeedback();
  }
};

const prevStep = () => {
  if (currentStep.value > 0) {
    currentStep.value--;
  }
};

const ratingUpdated = (step, rating) => {
  ratings.value[step] = rating;
};

const submitFeedback = async () => {
  try {
    const feedback = {
      ratingSatisfaction: ratings.value[0],
      ratingPace: ratings.value[1],
      ratingTopic: ratings.value[2],
      ratingTeacherAttitude: ratings.value[3],
      ratingRemarks: remarks.value,
      lessonId: props.lesson.id,
    };
    await lessonService.rate(feedback);
    isSubmitted.value = true;
  } catch (error) {
    eventBus.addAlert({ message: error });
  }
};
const closeFeedback = async () => {
  emit("close-lesson");
};
</script>

<style scoped>
.multi-step-rating {
  text-align: center;
  width: 600px;
  margin: 0 auto;
}

textarea {
  width: 90%;
  height: 100px;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #0056b3;
}
</style>
