<template>
  <div class="main-container">
    <h1>{{ $t("register.chooseInterestsTitle") }}</h1>
    <UserInterests @next-pressed="nextPressed" :variant="'onboarding'" />
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import UserInterests from "../components/UserInterests.vue";
const router = useRouter();

const nextPressed = async () => {
  router.push("/register/step-5");
};
</script>
