<template>
  <div class="main-container">
    <h1>{{ $t("register.locationAndLanguageTitle") }}</h1>
    <h3>{{ $t("register.locationAndLanguageSubTitle") }}</h3>
    <CountryPicker :country="country"></CountryPicker>
    <div class="input-container">
      <div class="input-label">{{ $t("register.city") }}</div>
      <input class="input-text" v-model="city" />
    </div>
    <LanguagePicker :language="nativeLanguage"></LanguagePicker>
    <LinguaButton @click="nextPressed">{{ $t("common.next") }}</LinguaButton>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { languages } from "../constants/languages.js";
const router = useRouter();
import userService from "@/services/userService";
import { getDefaultCountry } from "../constants/countries.js";
const country = ref("");
const nativeLanguage = ref("");
import CountryPicker from "../components/CountryPicker.vue";
import LinguaButton from "../components/LinguaButton.vue";
import LanguagePicker from "@/components/LanguagePicker.vue";

const city = ref("");

onMounted(async () => {
  try {
    const userData = await getUserData();

    if (userData.country) {
      country.value = userData.country;
    } else {
      country.value = getDefaultCountry();
    }
    if (userData.city) {
      city.value = userData.city;
    }
    if (userData.native_language) {
      nativeLanguage.value = userData.native_language;
    } else {
      const userLanguage = (navigator.language || navigator.userLanguage).slice(
        0,
        2
      );

      if (languages[userLanguage]) {
        nativeLanguage.value = languages[userLanguage];
        userService.setUserData({ native_language: nativeLanguage.value });
      }
    }
  } catch (error) {
    console.error(error.message);
  }
});

async function getUserData() {
  try {
    return await userService.getUserData();
  } catch (error) {
    alert(error.message);
  }
}

const nextPressed = async () => {
  await userService.setUserData({
    city: city.value,
  });
  router.push("/register/step-6");
};
</script>

<style>
.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
