<template>
  <div>
    <h3>{{ $t("language.chooseLevelSubtitle") }}</h3>

    <div class="select-languages-container">
      <form @submit.prevent="handleSave">
        <div
          v-for="(level, index) in Object.values(LanguageLevels)"
          :key="index"
          class="select-language-item"
        >
          <LinguaButton
            :variant="'selector'"
            :class="{ selected: selectedLevel === level }"
            @click="selectLevel(level)"
            class="select-language-button"
          >
            {{ level }}
          </LinguaButton>
          <div class="level-description">
            -&nbsp;&nbsp; {{ LanguageDescriptions[level] }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import LinguaButton from "./LinguaButton.vue";

import {
  LanguageLevels,
  LanguageDescriptions,
} from "../constants/languageLevels";

let selectedLevel = ref("");
import userService from "@/services/userService";

const emit = defineEmits(["level-chosen"]);

async function getUserData() {
  return await userService.getUserData();
}
const handleSave = async () => {
  try {
    await userService.setUserData({ language_level: selectedLevel.value });
    emit("level-chosen");
  } catch (error) {
    alert(error.message);
  }
};

onMounted(async () => {
  try {
    const userData = await getUserData();
    if (userData.language_level) {
      selectedLevel.value = userData.language_level;
    }
  } catch (error) {
    console.error(error.message);
  }
});

function selectLevel(level) {
  selectedLevel.value = level;
}
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";

.select-languages-container {
  width: 100%;
}
.select-language-item {
  display: flex;
  justify-content: center;
  margin: 10px;
}
.select-language-button {
  width: 100px;
}

.level-description {
  display: flex; /* Establishes this element as a flex container */
  flex-direction: column; /* Stacks flex items vertically */
  justify-content: center; /* Centers content vertically */
  align-items: flex-start; /* Centers content horizontally */
  width: 200px;
  padding-left: 10px;
}
</style>
