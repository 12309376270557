import api from "../utils/api";

export default {
  async listTeachers(data) {
    return api.get("teacher/list", data);
  },

  async myTeacher(data) {
    return api.get("teacher/my-teacher", data);
  },

  async voiceSample(data) {
    return api.post("teacher/voice-sample", data, { responseType: "blob" });
  },
};
