import api from "../utils/api";

export default {
  async start(lessonId, teacherId) {
    return await api.post(
      "lesson/start",
      {
        lessonId,
        teacherId,
      },
      { responseType: "json" }
    );
  },

  async listLessons() {
    return await api.get("lesson/list", {}, { responseType: "json" });
  },

  async listFinished() {
    return await api.get("lesson/finished", {}, { responseType: "json" });
  },

  async getCurrentLesson() {
    return await api.get("lesson/current", {}, { responseType: "json" });
  },

  async end() {
    return await api.patch("lesson/end", {}, { responseType: "json" });
  },

  async pause() {
    return await api.patch("lesson/pause", {}, { responseType: "json" });
  },

  async delete() {
    return await api.delete("lesson/current", {}, { responseType: "json" });
  },

  async rate(data) {
    return await api.patch("lesson/rate", data, { responseType: "json" });
  },
};
