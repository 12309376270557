<template>
  <div
    :class="[
      'teacher-box',
      {
        'teacher-box-selected': chosenTeacherId === teacher.id,
        'lesson-details': mode === 'lesson-details',
        'teacher-box-selected lesson-details':
          chosenTeacherId === teacher.id && mode === 'lesson-details',
      },
    ]"
  >
    <!-- Displaying properties of teacher -->
    <h2>{{ teacher.name }}</h2>
    <LinguaButton :variant="'clean'" @click="showTeacherDetails">
      <img :src="imagePath" class="avatar-small" />
    </LinguaButton>
    <p>{{ teacher.description }}</p>
    <p>{{ teacher.accent }}</p>

    <LinguaButton @click="showChooseTeacher" v-if="mode == 'lesson-details'">
      {{ $t("teacher.change") }}
    </LinguaButton>
    <LinguaButton @click="showTeacherDetails" v-else>
      {{ $t("teacher.select") }}
    </LinguaButton>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import LinguaButton from "../components/LinguaButton.vue";
const router = useRouter();

const props = defineProps({
  teacher: {
    type: Object,
    required: true,
  },
  mode: {
    type: String,
    required: false,
  },
  chosenTeacherId: {
    type: String,
    required: false,
  },
  lessonId: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(["show-teacher-details"]);

async function showTeacherDetails() {
  emit("show-teacher-details", props.teacher);
}

async function showChooseTeacher() {
  router.push(`/teachers/lesson/${props.lessonId}`);
}

onMounted(() => {});

const imagePath = computed(() => {
  const parts = props.teacher.slug.split("/");
  if (parts.length === 2) {
    return `/assets/avatars/${parts[0]}/${parts[1]}/${parts[1]}.jpg`;
  } else {
    console.error(
      "Slug format is incorrect. It should contain exactly one '/'."
    );
    return ""; // Return a default or error image path if needed
  }
});
</script>

<style lang="scss">
@import "../styles/variables.scss";
/* Styling for the teacher box */
.teacher-box {
  border: 1px solid #ddd;
  padding: 20px;
  margin: 10px;
  width: 200px;
  border-radius: 10px;
}

.teacher-box-selected {
  border: 1px solid $color-base-darker;
}
.teacher-box.lesson-details {
  padding: 0px;
  border: none;
}

.avatar-small {
  width: 80px;
  border-radius: 10px;
}
</style>
