import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import i18n from "./localization/i18n";
import store from "./store";

const app = createApp(App);
app.use(store);
app.use(i18n);
app.use(router);
app.mount("#app");
