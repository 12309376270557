<template>
  <div class="hint">
    {{ message }}
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "...",
    },
    duration: {
      type: Number,
      default: 3000, // 3 seconds
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style scoped>
.hint {
  position: absolute;
  top: -5px;
  right: 100%;
  margin-right: 10px;
  background-color: #f0f0f0;
  color: orangered;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  white-space: nowrap;
}
</style>
