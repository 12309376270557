<template>
  <div class="alert-container">
    <div v-for="(alert, index) in alerts" :key="index" class="alert">
      {{ alert.message }}
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { eventBus } from "../utils/eventBus";

const alerts = computed(() => eventBus.state.alerts);
</script>

<style scoped>
.alert-container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px; /* Set a fixed width or adjust as needed */
}

.alert {
  background-color: #eb1354; /* Red color for error */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
</style>
