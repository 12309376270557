<template>
  <h3>{{ $t("resetPassword.title") }}</h3>
  <div class="form-container">
    <div class="form-elements">
      <form @submit.prevent="sendPasswordResetEmail">
        <input
          type="email"
          v-model="email"
          :placeholder="$t('resetPassword.emailPlaceholder')"
          required
        />
        <LinguaButton type="submit">
          {{ $t("resetPassword.sendButton") }}
        </LinguaButton>
      </form>
      <p v-if="message">{{ message }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { auth } from "../utils/firebase.js";
import LinguaButton from "../components/LinguaButton.vue";

const { t } = useI18n();
const email = ref("");
const message = ref("");

const sendPasswordResetEmail = async () => {
  try {
    await auth.sendPasswordResetEmail(email.value);
    message.value = t("resetPassword.messageSuccess");
  } catch (error) {
    console.error("Error sending password reset email:", error);
    message.value = t("resetPassword.messageError");
  }
};
</script>

<style scoped>
/* Styles here if necessary */
</style>
