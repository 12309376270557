<template>
  <div class="loader-wrapper">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "LoaderSpinner",
};
</script>

<style lang="scss">
@import "../styles/variables.scss";

.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid $color-base;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}
.loader-wrapper {
  padding: 40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
