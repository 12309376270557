import { createStore } from "vuex";
import VuexPersist from "vuex-persist";

const vuexPersist = new VuexPersist({
  key: "linguaboost", // The key to store the persisted state under
  storage: window.localStorage, // Use localStorage as the storage engine
});

// Create a new store instance.
const store = createStore({
  state() {
    return {
      email: "",
      speakerOn: true,
    };
  },
  mutations: {
    // Mutation to update the email
    setEmail(state, email) {
      state.email = email;
    },

    setSpeakerOn(state, speakerOn) {
      state.speakerOn = speakerOn;
    },
  },
  plugins: [vuexPersist.plugin],
});

export default store;
