import { reactive } from "vue";

const state = reactive({
  alerts: [],
});

export const eventBus = {
  state,
  addAlert(alert) {
    state.alerts.push(alert);
    setTimeout(() => {
      state.alerts.shift();
    }, 3000); // Remove alert after 3 seconds
  },
};
