<template>
  <div class="main-container">
    <h1>{{ $t("register.title") }}</h1>
    <h4>{{ $t("register.welcome") }}</h4>
    <div class="form-container">
      <div class="form-elements">
        <form @submit.prevent="handleRegister">
          <input
            id="email"
            type="email"
            v-model="emailInput"
            :placeholder="$t('register.email')"
          />

          <LinguaButton type="submit">{{
            $t("register.registerButton")
          }}</LinguaButton>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import LinguaButton from "../components/LinguaButton.vue";

import { useStore } from "vuex";
import { ref, computed } from "vue";

const store = useStore();
const email = computed(() => store.state.email);
const emailInput = ref(email.value);

const router = useRouter();

function handleRegister() {
  if (emailInput.value) {
    store.commit("setEmail", emailInput);
    router.push("/register/step-2", {});
  }
}
</script>
