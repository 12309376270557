<template>
  <div class="main-container">
    <h1>{{ $t("register.lessonTypeInterestingTitle") }}</h1>

    <LearningPreference @learning-preference-chosen="teachingTypeChosen" />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
import userService from "@/services/userService";
const learningType = ref(null);

import LearningPreference from "@/components/LearningPreference.vue";

onMounted(async () => {
  try {
    const userData = await userService.getUserData();

    if (userData.learning_frequency) {
      learningType.value = userData.preferred_teaching_type;
    }
  } catch (error) {
    console.error(error.message);
  }
});

function teachingTypeChosen() {
  router.push("/register/lets-start");
}
</script>

<style>
.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
