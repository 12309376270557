<template>
  <div class="main-container">
    <h1>{{ $t("register.completedTitle") }}</h1>
    <p>{{ $t("register.completedMessage") }}</p>
    <LinguaButton @click="letsStartPressed">
      {{ $t("register.letsStart") }}
    </LinguaButton>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import LinguaButton from "@/components/LinguaButton.vue";
const router = useRouter();
import userService from "@/services/userService";
const learningType = ref(null);

onMounted(async () => {
  try {
    const userData = await userService.getUserData();

    if (userData.learning_frequency) {
      learningType.value = userData.preferred_teaching_type;
    }
  } catch (error) {
    console.error(error.message);
  }
});

function letsStartPressed() {
  router.push("/teachers");
}
</script>

<style>
.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
