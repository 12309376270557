<template>
  <div
    :class="[
      'lesson-row',
      mode == 'continue' ? 'continue-mode' : 'select-mode',
    ]"
  >
    <!-- Displaying properties of teacher -->

    <div class="lesson-name-container">
      <div class="lesson-name">{{ lesson.name }}</div>
      <div class="lesson-description">{{ lesson.description }}</div>
    </div>
    <div class="lesson-select-container">
      <LinguaButton @click="selectLesson">
        {{ mode == "continue" ? $t("lesson.continue") : $t("lesson.select") }}
      </LinguaButton>
      <div class="time-box">
        {{ lesson.total_time ? lesson.total_time + " min" : "45 min" }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import LinguaButton from "../components/LinguaButton.vue";

const props = defineProps({
  lesson: {
    type: Object,
    required: true,
  },
  mode: {
    type: String,
    required: false,
  },
});
const router = useRouter();
const emit = defineEmits(["show-lesson-details"]);

async function selectLesson() {
  if (props.mode == "continue") {
    router.push("/lesson", {
      props: { lesson: () => props.lesson, teacher: () => props.teacher },
    });
  } else {
    emit("show-lesson-details", props.lesson);
  }
}
</script>

<style lang="scss">
@import "../styles/variables.scss";
/* Styling for the teacher box */

.lesson-name {
  font-size: 20px;
  text-align: left;
}

.lesson-row {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 14px;
  margin: 10px;
  text-align: left;
}

.lesson-name-container {
  width: 100%;
}

.time-box {
  text-align: right;
  font-size: 14px;
  color: $color-base-darker;
  margin-top: 14px;
}
</style>
