import api from "../utils/api";

export default {
  setUserData(data) {
    return api.post("user/set-user-data", data);
  },
  addUserInterest(data) {
    return api.post("user/add-user-interest", data);
  },
  removeUserInterest(data) {
    return api.post("user/remove-user-interest", data);
  },
  async getUserData() {
    const response = await api.get("/user/get-user-data", {});
    return response.data.user;
  },
  async changeEmail(email) {
    const response = await api.post("/user/change-email", {
      email,
    });
    return response.data;
  },
  async changePassword(password) {
    const response = await api.post("/user/change-password", {
      password,
    });
    return response.data;
  },
  async deleteAccount() {
    const response = await api.delete("/user/delete-account");
    return response.data;
  },

  async selectTeacher(teacherId) {
    const response = await api.post("/user/select-teacher", { teacherId });
    return response.data;
  },
};
