<template>
  <div>
    <h2>{{ $t("settings.title") }}</h2>

    <ul class="tabs">
      <li
        class="tab-item"
        v-for="tab in tabs"
        :key="tab.id"
        @click="changeTab(tab.id)"
      >
        <a class="nav-link" :class="{ active: activeTab === tab.id }" href="#">
          {{ $t(`settings.${tab.label}`) }}
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <UserProfile v-if="activeTab === 'userProfile'" />
      <LanguageLevel v-if="activeTab === 'languageLevel'" />
      <UserInterests v-if="activeTab === 'interests'" />
      <LearningPreference v-if="activeTab === 'learningPreference'" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import UserProfile from "../components/UserProfile.vue";
import LanguageLevel from "../components/LanguageLevel.vue";
import UserInterests from "../components/UserInterests.vue";
import LearningPreference from "../components/LearningPreference.vue";

const router = useRouter();
const route = useRoute();
const activeTab = ref("userProfile"); // Default tab

// Define the tabs
const tabs = [
  { id: "userProfile", label: "userProfile" },
  { id: "languageLevel", label: "languageLevel" },
  { id: "interests", label: "interests" },
  { id: "learningPreference", label: "learningPreference" },
];

// Function to change tabs and update URL
const changeTab = (tabId) => {
  router.push({ path: "/settings", query: { tab: tabId } }).catch((err) => {
    console.log(err);
  });
  activeTab.value = tabId;
};

// Watch for route changes to update the active tab
watch(
  () => route.query.tab,
  (newTab) => {
    if (newTab && tabs.some((tab) => tab.id === newTab)) {
      activeTab.value = newTab;
    }
  }
);

// Set the initial tab based on the URL on component mount
onMounted(() => {
  const tabQuery = route.query.tab;
  if (tabQuery && tabs.some((tab) => tab.id === tabQuery)) {
    activeTab.value = tabQuery;
  }
});
</script>

<style scoped>
/* Tabs Container Styling */
.tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  border-bottom: 1px solid #eee; /* Optional: adds a light border under all tabs */
}

/* Individual Tab Styling */
.tab-item {
  margin-right: 10px; /* Space between tabs */
}

/* Navigation Link Styling */
.nav-link {
  display: block; /* Makes the anchor fill the li for better click area */
  padding: 10px;
  color: #000; /* Text color */
  text-decoration: none; /* No underline */
  position: relative; /* Position relative for the absolute positioning of the pseudo-element */
  overflow: hidden; /* Ensures the pseudo-element does not show outside the anchor bounds */
}

/* Pseudo-element for active state */
.nav-link::after {
  content: ""; /* Required for display */
  position: absolute;
  bottom: 0; /* Position it right under the tab */
  left: 50%; /* Center the element */
  width: 0%; /* Start with no width */
  height: 2px; /* Line thickness */
  background-color: #0056b3; /* Line color */
  transition: width 0.2s ease, left 0.2s ease; /* Animate width and horizontal position */
}

/* Active State Styling */
.nav-link.active::after {
  width: 100%; /* Full width when active */
  left: 0; /* Align to the left edge */
}
</style>
