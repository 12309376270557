<template>
  <div class="main-container">
    <div ref="lessonsContainer" class="lessons-container" v-if="isDataLoaded">
      <div
        v-if="
          currentLesson ||
          lessons.length > 0 ||
          suggestedLessons.length > 0 ||
          otherLessons.length > 0
        "
      >
        <div v-if="currentLesson">
          <div class="lesson-section-header">{{ $t("lessons.current") }}</div>
          <LessonBox
            :lesson="currentLesson"
            @show-lesson-details="handleShowDetails"
            :mode="'continue'"
          >
          </LessonBox>
        </div>
        <div v-if="lessons.length > 0">
          <LessonRow
            v-for="lesson in lessons"
            :key="lesson.id"
            :lesson="lesson"
            @show-lesson-details="handleShowDetails"
          >
          </LessonRow>
        </div>
        <div v-if="suggestedLessons.length > 0">
          <div class="lesson-section-header">{{ $t("lessons.suggested") }}</div>
          <LessonRow
            v-for="lesson in suggestedLessons"
            :key="lesson.id"
            :lesson="lesson"
            @show-lesson-details="handleShowDetails"
          >
          </LessonRow>
        </div>
        <div v-if="otherLessons.length > 0">
          <div class="lesson-section-header">{{ $t("lessons.other") }}</div>
          <LessonRow
            v-for="lesson in otherLessons"
            :key="lesson.id"
            :lesson="lesson"
            @show-lesson-details="handleShowDetails"
          >
          </LessonRow>
        </div>
      </div>
      <div v-else>
        <div class="nodatayet">{{ $t("common.nodatayet") }}</div>
      </div>
    </div>

    <LoaderSpinner v-else> </LoaderSpinner>
  </div>
  <transition name="fade">
    <LessonDetailsPopup
      v-if="selectedLesson"
      :lesson="selectedLesson"
      :teacher="myTeacher"
      @close="closePopup"
    />
  </transition>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

import LessonDetailsPopup from "./LessonDetailsPopup.vue";

import LoaderSpinner from "./LoaderSpinner.vue";
import LessonBox from "./LessonBox.vue";
import LessonRow from "./LessonRow.vue";
import lessonService from "../services/lessonService";
import teacherService from "@/services/teacherService";

const props = defineProps({
  lessonId: {
    type: String,
    required: false,
  },
});

const lessons = ref([]);
const suggestedLessons = ref([]);
const otherLessons = ref([]);
const currentLesson = ref(null);
const isDataLoaded = ref(false);
const selectedLesson = ref(null);
const myTeacher = ref(null);
const router = useRouter();
const route = useRoute();

onMounted(async () => {
  try {
    let res = await teacherService.myTeacher();

    if (res.data.teacher) {
      const tabId = route.query.tab;

      myTeacher.value = res.data.teacher;
      if (tabId === "finished") {
        res = await lessonService.listFinished();
        lessons.value = res.data.lessons;
      } else {
        res = await lessonService.listLessons();
        suggestedLessons.value = res.data.lessons.suggested;
        otherLessons.value = res.data.lessons.other;
        currentLesson.value = res.data.lessons.current?.lesson;

        if (props.lessonId) {
          selectedLesson.value = otherLessons.value.find(
            (lesson) => lesson.id === parseInt(props.lessonId)
          );

          // If not found in otherLessons, search in suggestedLessons
          if (!selectedLesson.value) {
            selectedLesson.value = suggestedLessons.value.find(
              (lesson) => lesson.id === parseInt(props.lessonId)
            );
          }
        }
      }

      isDataLoaded.value = true;
    } else {
      router.push("/teachers");
    }
  } catch (error) {
    console.error(error.message);
  }
});

function handleShowDetails(lesson) {
  selectedLesson.value = lesson;
}

function closePopup() {
  selectedLesson.value = null;
}
</script>

<style>
.main-container {
  width: 100%; /* Ensures the container takes the full width */
}

.lessons-container {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.lesson-section-header {
  font-family: "Lato-Regular", sans-serif;
  margin-top: 30px;
  text-align: left;
  text-transform: uppercase;
}
.nodatayet {
  padding: 40px;
  width: 100%;
}
</style>
