<template>
  <div class="popup" @click="closePopup">
    <div class="popup-content" @click.stop>
      <p>
        {{ $t("lesson.areYouSureToStop") }}
      </p>
      <LinguaButton @click="confirmPause">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="6" y="4" width="4" height="16" fill="currentColor" />
          <rect x="14" y="4" width="4" height="16" fill="currentColor" />
        </svg>

        {{ $t("common.pauseAndContinueLater") }}</LinguaButton
      >
      <LinguaButton @click="confirmStop">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 3H16L20 7V21H4V3Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 3V7H4V3H16Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 14V20"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 14V20"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8 3V7"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        {{ $t("common.saveAndFinish") }}</LinguaButton
      >
      <LinguaButton @click="confirmDelete" :variant="'delete'">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 6H5H21"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8 6V4C8 3.44772 8.44772 3 9 3H15C15.5523 3 16 3.44772 16 4V6M19 6V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20V6H19Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 11V17"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14 11V17"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        {{ $t("common.deleteProgressAndExit") }}</LinguaButton
      >
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue";
import lessonService from "@/services/lessonService";

import LinguaButton from "./LinguaButton.vue";

const emit = defineEmits(["close"]);

function closePopup() {
  emit("close");
}

const handleEscapeKey = (event) => {
  if (event.key === "Escape") {
    onEscapePressed();
  }
};

async function confirmStop() {
  await lessonService.end();
  emit("close");
  emit("lesson-stopped");
}

async function confirmPause() {
  await lessonService.pause();
  emit("close");
  emit("close-lesson");
}

async function confirmDelete() {
  await lessonService.delete();
  emit("close");
  emit("close-lesson");
}

const onEscapePressed = () => {
  closePopup();
};

onMounted(() => {
  window.addEventListener("keydown", handleEscapeKey);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleEscapeKey);
});
</script>

<style></style>
