<template>
  <div class="user-profile">
    <!-- Form to set name -->
    <form @submit.prevent="updateProfile">
      <CountryPicker :country="country"></CountryPicker>
      <TimezonePicker :timezone="timezone"></TimezonePicker>
      <input
        v-model="name"
        :placeholder="$t('profile.nameLabel')"
        type="text"
      />
      <LinguaButton type="submit">{{
        $t("profile.updateNameButton")
      }}</LinguaButton>
    </form>

    <!-- Form to change email -->
    <form @submit.prevent="changeEmail">
      <input
        v-model="email"
        :placeholder="$t('profile.emailLabel')"
        type="email"
      />
      <LinguaButton type="submit">{{
        $t("profile.changeEmailButton")
      }}</LinguaButton>
    </form>

    <!-- Form to change password -->
    <form @submit.prevent="changePassword">
      <input
        v-model="password"
        :placeholder="$t('profile.passwordLabel')"
        type="password"
      />
      <LinguaButton type="submit">{{
        $t("profile.changePasswordButton")
      }}</LinguaButton>
    </form>

    <!-- Button to delete account -->
    <LinguaButton @click="deleteAccount">
      {{ $t("profile.deleteAccountButton") }}
    </LinguaButton>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import moment from "moment-timezone";
import { useI18n } from "vue-i18n";
import userService from "@/services/userService";
import LinguaButton from "./LinguaButton.vue";
import CountryPicker from "./CountryPicker.vue";
import TimezonePicker from "./TimezonePicker.vue";
import { getDefaultCountry } from "../constants/countries.js";

const { t } = useI18n();
const name = ref("");
const email = ref("");
const password = ref("");
const country = ref("");
const timezone = ref("");
const city = ref("");

onMounted(async () => {
  try {
    const userData = await getUserData();
    name.value = userData.name; // Set the fetched name to your name ref
    email.value = userData.email;
    if (userData.country) {
      country.value = userData.country;
    } else {
      country.value = getDefaultCountry();
    }
    if (userData.timezone) {
      timezone.value = userData.timezone;
    } else {
      timezone.value = getDefaultTimezone();
    }
    city.value = userData.city;
  } catch (error) {
    console.error(error.message);
  }
});

async function getUserData() {
  try {
    return await userService.getUserData();
  } catch (error) {
    alert(error.message);
  }
}

function getDefaultTimezone() {
  return moment.tz.guess();
}

async function updateProfile() {
  try {
    await userService.setUserData({ name: name.value });
    alert(t("profile.updateNameSuccess"));
  } catch (error) {
    alert(error.message);
  }
}

async function changeEmail() {
  try {
    await userService.changeEmail(email.value);
    alert(t("profile.updateEmailSuccess"));
  } catch (error) {
    alert(error.message);
  }
}

async function changePassword() {
  try {
    await userService.changePassword(password.value);
    alert(t("profile.updatePasswordSuccess"));
  } catch (error) {
    alert(error.message);
  }
}

async function deleteAccount() {
  try {
    await userService.deleteAccount();
    alert(t("profile.deleteAccountSuccess"));
  } catch (error) {
    alert(error.message);
  }
}
</script>

<style scoped>
.user-profile {
  max-width: 400px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
input {
  margin: 5px;
  min-width: 200px;
}
</style>
