<template>
  <div class="main-container">
    <h1>{{ $t("register.title") }}</h1>
    <h4>{{ $t("register.provideNameAndPassword") }}</h4>
    <div class="form-container">
      <div class="form-elements">
        <form @submit.prevent="handleRegister">
          <input
            id="name"
            v-model="nameInput"
            :placeholder="$t('register.name')"
          />

          <input
            id="email"
            type="email"
            v-model="emailInput"
            :placeholder="$t('register.email')"
          />
          <input
            id="password"
            type="password"
            v-model="password"
            :placeholder="$t('register.password')"
          />

          <LinguaButton type="submit">{{
            $t("register.registerButton")
          }}</LinguaButton>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { eventBus } from "../utils/eventBus";
import { ref, computed } from "vue";
// import { useI18n } from "vue-i18n";
import { auth } from "../utils/firebase.js";
import { createUserWithEmailAndPassword } from "firebase/auth";
import LinguaButton from "../components/LinguaButton.vue";
import userService from "../services/userService";

import { useStore } from "vuex";
import { useRouter } from "vue-router";

// const { t } = useI18n(); // This enables the use of i18n instance
const store = useStore();
const router = useRouter();
const email = computed(() => store.state.email);
const password = ref("");
const name = ref("");
const emailInput = ref(email.value);
const nameInput = ref(name.value);

const submitUserData = async (userData) => {
  try {
    const response = await userService.setUserData(userData);
    console.log("User data updated:", response.data);
  } catch (error) {
    console.error("Error updating user data:", error.response.data);
  }
};

const handleRegister = async () => {
  try {
    await createUserWithEmailAndPassword(
      auth,
      emailInput.value,
      password.value
    );
    await submitUserData({ name: nameInput.value });
    router.push("/register/step-3", {});
  } catch (error) {
    eventBus.addAlert({ message: error.message });
  }
};
</script>
