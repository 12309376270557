<template>
  <ButtonChoiceSelector
    :title="$t('register.lessonTypeInterestingTitle')"
    :subtitle="$t('register.lessonTypeInterestingSubTitle')"
    :options="learningTypes"
    :modelColumnName="'preferred_teaching_type'"
    v-model="learningType"
    @selector-value-chosen="learningPreferenceChosen"
  />
</template>

<script setup>
const emit = defineEmits(["update:modelValue", "selector-value-chosen"]);
import { learningTypes } from "@/constants/learningTypes.js";
import ButtonChoiceSelector from "../components/ButtonChoiceSelector.vue";

function learningPreferenceChosen(value) {
  emit("learningPreferenceChosen", value);
}
</script>
