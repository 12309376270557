<template>
  <div class="picker-container">
    <div class="picker-label">{{ $t("profile.country") }}</div>
    <div class="country-picker">
      <select v-model="selectedCountry" @change="updateCountry">
        <option
          v-for="country in countries"
          :key="country.code"
          :value="country.code"
        >
          {{ country.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { countries } from "../constants/countries.js";
import userService from "@/services/userService";

const props = defineProps({
  country: String,
});

const selectedCountry = ref(props.country);

// Watch for changes to the country prop
watch(
  () => props.country,
  (newCountry) => {
    selectedCountry.value = newCountry;
  }
);

// Set the selectedCountry based on the initial prop value on component mount
onMounted(() => {
  selectedCountry.value = props.country;
});

function updateCountry() {
  userService.setUserData({ country: selectedCountry.value });
}
</script>

<style scoped>
.country-picker select {
  padding: 8px;
  width: 200px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.picker-label {
  width: 150px;
  padding: 10px;
  text-align: right;
}
.picker-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}
</style>
