<template>
  <div class="picker-container">
    <div class="picker-label">{{ $t("profile.timezone") }}</div>
    <div class="timezone-picker">
      <select v-model="selectedTimezone" @change="updateTimezone">
        <option v-for="timezone in timezones" :key="timezone" :value="timezone">
          {{ timezone }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import moment from "moment-timezone";
import userService from "@/services/userService";

const props = defineProps({
  timezone: String,
});

const timezones = moment.tz.names();
const selectedTimezone = ref(props.timezone || moment.tz.guess());

watch(
  () => props.timezone,
  (newTimezone, oldTimezone) => {
    if (newTimezone !== oldTimezone) {
      selectedTimezone.value = newTimezone;
    }
  }
);

onMounted(() => {
  console.log(
    "Component is mounted. Current timezone:",
    selectedTimezone.value
  );
});

function updateTimezone() {
  userService.setUserData({ timezone: selectedTimezone.value });
}
</script>

<style scoped>
.timezone-picker select {
  padding: 8px;
  width: 200px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.picker-label {
  padding: 10px;
}
.picker-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}
</style>
