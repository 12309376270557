<template>
  <div class="star-rating">
    <svg
      v-for="n in 5"
      :key="n"
      :class="{ filled: n <= rating }"
      @click="setRating(n)"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <polygon
        points="12 2 15 8.6 22 9.3 17 14 18.2 21 12 18 5.8 21 7 14 2 9.3 9 8.6 12 2"
      />
    </svg>
  </div>
</template>

<script setup>
import { ref } from "vue";
const emit = defineEmits(["rating-updated"]);

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
});

const rating = ref(props.modelValue);

const setRating = (n) => {
  rating.value = n;
  emit("rating-updated", n);
};
</script>

<style scoped>
.star-rating {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

svg {
  cursor: pointer;
  transition: fill 0.2s ease;
}

svg.filled {
  fill: #ffd700;
}
</style>
