export function createMicrophone() {
  let stream;
  let audioContext;
  let audioWorkletNode;
  let source;
  let audioBufferQueue = new Int16Array(0);
  return {
    async requestPermission() {
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    },
    async startRecording(onAudioCallback) {
      if (!stream) {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      }
      let is16kAudioContext = true;
      try {
        audioContext = new AudioContext({
          sampleRate: 16_000,
          latencyHint: "balanced",
        });

        source = audioContext.createMediaStreamSource(stream);
      } catch (error) {
        is16kAudioContext = false;

        // Fall back to creating an AudioContext without specifying the sample rate
        audioContext = new AudioContext({ latencyHint: "balanced" });
        source = audioContext.createMediaStreamSource(stream);
      }

      if (!is16kAudioContext) {
        await audioContext.audioWorklet.addModule("audio-resampler.js");
        audioWorkletNode = new AudioWorkletNode(
          audioContext,
          "audio-resampler"
        );
      } else {
        await audioContext.audioWorklet.addModule("audio-processor.js");
        audioWorkletNode = new AudioWorkletNode(
          audioContext,
          "audio-processor"
        );
      }

      source.connect(audioWorkletNode);
      audioWorkletNode.connect(audioContext.destination);
      audioWorkletNode.port.postMessage({
        contextSampleRate: audioContext.sampleRate,
      });
      audioWorkletNode.port.onmessage = (event) => {
        const currentBuffer = new Int16Array(event.data.audio_data);
        audioBufferQueue = mergeBuffers(audioBufferQueue, currentBuffer);

        const bufferDuration =
          (audioBufferQueue.length / audioContext.sampleRate) * 1000;

        // wait until we have 100ms of audio data
        if (bufferDuration >= 100) {
          const totalSamples = Math.floor(audioContext.sampleRate * 0.1);

          const finalBuffer = new Uint8Array(
            audioBufferQueue.subarray(0, totalSamples).buffer
          );

          audioBufferQueue = audioBufferQueue.subarray(totalSamples);
          if (onAudioCallback) onAudioCallback(finalBuffer);
        }
      };
    },
    stopRecording() {
      stream?.getTracks().forEach((track) => track.stop());
      // await audioContext?.close();
      audioBufferQueue = new Int16Array(0);
    },
    async cleanup() {
      // Stop and release the stream
      stream?.getTracks().forEach((track) => track.stop());
      stream = null;

      // Disconnect and close the audio nodes
      if (audioWorkletNode) {
        audioWorkletNode.port.onmessage = null;
        audioWorkletNode.disconnect();
        audioWorkletNode = null;
      }

      if (source) {
        source.disconnect();
        source = null;
      }

      if (audioContext) {
        if (audioContext.state !== "closed") {
          await audioContext.close();
        }
        audioContext = null;
      }

      // Clear the audio buffer
      audioBufferQueue = new Int16Array(0);
    },
  };
}

function mergeBuffers(lhs, rhs) {
  const mergedBuffer = new Int16Array(lhs.length + rhs.length);
  mergedBuffer.set(lhs, 0);
  mergedBuffer.set(rhs, lhs.length);
  return mergedBuffer;
}
